import React, {useState} from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import OverlayTooltip from './OverlayTooltip';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';

export default function FormForXing() {
  const { t } = useTranslation();

  const [job, setJob] = useState("");
  const [similar, setSimilar] = useState(false);
  const [location, setLocation] = useState("");
  const [exclude, setExclude] = useState("");  

  function handleClickButton() {
    ReactGA.ga('send', 'event', {
      eventCategory: 'Xing',
      eventAction: job,
      eventLabel: 'keywords: '+location+', exclude: '+exclude
    });
    let thisSimilar = (similar) ? "~" : "";
    window.open(
      "http://www.google.com/search?q=site:xing.com/profile/" + 
      ((job) ? " intitle:" + thisSimilar + "\"" + job + "\"" : thisSimilar) + 
      ((location) ? "+\"" + location + "\"" : "") + 
      ((exclude) ? "-\"" + exclude + "\"" : "")
    );    
  }

  return (
    <>
      <Form>
        <div className="form-title">
          {t('xing.title')}
        </div>      
          <Form.Row>                      
            <Form.Group as={Col} md>
              <Form.Row>
                <Col xs="auto">
                  <Form.Label>{t("xing.jobTitle")}</Form.Label>
                </Col>
                <Col className="d-flex justify-content-end">
                  <OverlayTooltip 
                    message={t('xing.jobTitleToolTip')}
                  />
                </Col>
              </Form.Row>          
              <Form.Control               
                placeholder={t('xing.jobTitlePlaceHolder')}           
                value={job} 
                onChange={(event) => setJob(event.target.value)}
              />          
              <Form.Check 
                type="checkbox" 
                label={t('xing.similarJobs')}
                value={similar} 
                onChange={() => setSimilar(!similar)}
              />
            </Form.Group>
            <Form.Group as={Col} md>
              <Form.Row>
                <Col xs="auto">
                  <Form.Label>{t('xing.locationInclude')}</Form.Label>
                </Col>
                <Col className="d-flex justify-content-end">
                  <OverlayTooltip 
                    message={t('xing.locationIncludeToolTip')}
                  />
                </Col>
              </Form.Row>                    
              <Form.Control               
                placeholder={t('xing.locationIncludePlaceHolder')}
                value={location} 
                onChange={(event) => setLocation(event.target.value)}
              />          
            </Form.Group>
          </Form.Row>      
          <Form.Row>          
            <Form.Group as={Col} md>
              <Form.Row>
                <Col xs="auto">
                  <Form.Label>{t('xing.keywordExclude')}</Form.Label>
                </Col>
                <Col className="d-flex justify-content-end">
                  <OverlayTooltip 
                    message={t('xing.keywordExcludeToolTip')}
                  />
                </Col>
              </Form.Row>                              
              <Form.Control 
                placeholder={t('xing.keywordExcludePlaceHolder')}          
                value={exclude} 
                onChange={(event) => setExclude(event.target.value)}
              />          
            </Form.Group>
            <Col md/>
          </Form.Row>      
          <Button 
            variant="success" 
            size="lg" 
            onClick={handleClickButton}
            block
          >
            {t('xing.findPeople')}
          </Button>                                    
      </Form>
    </>    
  );
}  

import React, { useState } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import OverlayTooltip from './OverlayTooltip';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';

export default function FormForTwitter() {
  const { t } = useTranslation();

  const [cityOrCountry, setCityOrCountry] = useState("");  
  const [skillsInklude, setSkillsInklude] = useState("");
  const [skillsExclude, setSkillsExclude] = useState("");

  function handleClickButton() {
    ReactGA.ga('send', 'event', {
      eventCategory: 'Twitter',
      eventAction: skillsInklude,
      eventLabel: 'city: '+cityOrCountry+', exclude: '+skillsExclude
    });
    
    window.open(
      "http://www.google.com/search?q=site:twitter.com -inurl:" + 
      "(search|favorites|status|statuses|jobs) -intitle:(job|jobs) -recruiter -HR -careers" +
      ((skillsInklude) ? "+\"" + skillsInklude + "\"" : "") +
      ((cityOrCountry) ? "+\"" + cityOrCountry + "\"" : "") +
      ((skillsExclude) ? "-\"" + skillsExclude + "\"" : "")
    );    
  }
  
  return (
    <>
      <Form>      
        <div className="form-title">
          {t('twitter.title')}
        </div>      
          <Form.Row>                      
            <Form.Group as={Col} md>
              <Form.Row>
                <Col xs="auto">
                  <Form.Label>{t('twitter.cityCountry')}</Form.Label>
                </Col>
                <Col className="d-flex justify-content-end">
                  <OverlayTooltip 
                    message={t('twitter.cityCountryToolTip')}
                  />
                </Col>
              </Form.Row>          
              <Form.Control               
                placeholder={t('twitter.cityCountryPlaceHolder')}               
                value={cityOrCountry} 
                onChange={(event) => setCityOrCountry(event.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} md>
              <Form.Row>
                <Col xs="auto">
                  <Form.Label>{t('twitter.locationInclude')}</Form.Label>
                </Col>
                <Col className="d-flex justify-content-end">
                  <OverlayTooltip 
                    message={t('twitter.locationIncludeToolTip')}
                  />
                </Col>
              </Form.Row>                    
              <Form.Control               
                placeholder={t('twitter.locationIncludePlaceHolder')}               
                value={skillsInklude} 
                onChange={(event) => setSkillsInklude(event.target.value)}
              />          
            </Form.Group>
          </Form.Row>
          <Form.Row>          
            <Form.Group as={Col} md>
              <Form.Row>
                <Col xs="auto">
                  <Form.Label>{t('twitter.keywordExclude')}</Form.Label>
                </Col>
                <Col className="d-flex justify-content-end">
                  <OverlayTooltip 
                    message={t('twitter.keywordExcludeToolTip')}
                  />
                </Col>
              </Form.Row>                              
              <Form.Control 
                placeholder={t('twitter.keywordExcludePlaceHolder')}             
                value={skillsExclude} 
                onChange={(event) => setSkillsExclude(event.target.value)}
              />          
            </Form.Group>
            <Col md/>
          </Form.Row>    
          <Button 
            variant="success" 
            size="lg" 
            onClick={handleClickButton}
            block
          >
            {t('twitter.findPeople')}
          </Button>                                    
      </Form>
    </>
  );
}  

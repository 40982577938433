import React, { useState } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import OverlayTooltip from './OverlayTooltip';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';

export default function FormForLinkedIn() {
  const { t } = useTranslation();
  
  const [country, setCountry] = useState("all");
  const [job, setJob] = useState("");
  const [similar, setSimilar] = useState(false);
  const [location, setLocation] = useState("");
  const [exclude, setExclude] = useState("");
  const [education, setEducation] = useState("all");
  const [employer, setEmployer] = useState("");    

  function handleClickButton() {
    ReactGA.ga('send', 'event', {
      eventCategory: 'LinkedIn',
      eventAction: job,
      eventLabel: 'country: '+country+', keywords: '+location+', exclude: '+exclude+', education: '+education+', employer: '+employer
    });
    window.open(
      "https://www.google.com/search?q=" + 
      (similar ? "~" : "") + 
      (job ? "+\"" + job + "\"" : "") + 
      (location ? "+\"" + location + "\"" : "") + 
      (exclude ? " -\"" + exclude + "\"" : "") + 
      " -intitle:\"profiles\" -inurl:\"dir/+\"+site:" + 
      ((country !== "all") ? country + "." : "") + 
      "linkedin.com/in/+OR+site:" + 
      ((country !== "all") ? country + "." : "") + 
      "linkedin.com/pub/" + 
      ((education !== "all") ? "&as_oq=" + education : "") + 
      (employer ? "+\"Current+%2A+" + employer + "+%2A+\"" : "")
    );
  }    

  return (
    <>
      <Form>            
        <div className="form-title">
          {t('linkedIn.title')}
        </div>
          <Form.Row>            
            <Form.Group as={Col} md>          
              <Form.Row>
                <Col xs="auto">
                  <Form.Label>{t('linkedIn.country')}</Form.Label>
                </Col>
                <Col className="d-flex justify-content-end">
                  <OverlayTooltip message={t('linkedIn.countryToolTip')}/>
                </Col>
              </Form.Row>                                        
              <Form.Control                                          
                as="select" 
                value={country} 
                onChange={(event) => setCountry(event.target.value)}
              >              
                <option value="all">{t('linkedIn.allCountry')}</option>
                <option value="uk">United Kingdom</option>
                <option value="www">United States</option>
                <option value="ca">Canada</option>
                <option value="af">Afghanistan</option>
                <option value="al">Albania</option>
                <option value="dz">Algeria</option>
                <option value="ao">Angola</option>
                <option value="ar">Argentina</option>
                <option value="am">Armenia</option>
                <option value="aw">Aruba</option>
                <option value="au">Australia</option>
                <option value="at">Austria</option>
                <option value="bh">Bahrain</option>
                <option value="bd">Bangladesh</option>
                <option value="by">Belarus</option>
                <option value="be">Belgium</option>
                <option value="bz">Belize</option>
                <option value="bo">Bolivia</option>
                <option value="ba">Bosnia and Herzegovina</option>
                <option value="br">Brazil</option>
                <option value="bg">Bulgaria</option>
                <option value="cl">Chile</option>
                <option value="cn">China</option>
                <option value="co">Colombia</option>
                <option value="cr">Costa Rica</option>
                <option value="hr">Croatia</option>
                <option value="cy">Cyprus</option>
                <option value="cz">Czech Republic</option>
                <option value="dk">Denmark</option>
                <option value="dm">Dominica</option>
                <option value="do">Dominican Republic</option>
                <option value="ec">Ecuador</option>
                <option value="eg">Egypt</option>
                <option value="sv">El Salvador</option>
                <option value="ee">Estonia</option>
                <option value="fk">Falkland Islands</option>
                <option value="fi">Finland</option>
                <option value="fr">France</option>
                <option value="ge">Georgia</option>
                <option value="de">Germany</option>
                <option value="gh">Ghana</option>
                <option value="gi">Gibraltar</option>
                <option value="gr">Greece</option>
                <option value="gt">Guatemala</option>
                <option value="hk">Hong Kong</option>
                <option value="hu">Hungary</option>
                <option value="is">Iceland</option>
                <option value="in">India</option>
                <option value="id">Indonesia</option>
                <option value="ir">Iran</option>
                <option value="ie">Ireland</option>
                <option value="il">Israel</option>
                <option value="it">Italy</option>
                <option value="jm">Jamaica</option>
                <option value="jp">Japan</option>
                <option value="jo">Jordan</option>
                <option value="kz">Kazakhstan</option>
                <option value="ke">Kenya</option>
                <option value="kr">Korea</option>
                <option value="kw">Kuwait</option>
                <option value="la">Laos</option>
                <option value="lv">Latvia</option>
                <option value="lb">Lebanon</option>
                <option value="lu">Luxembourg</option>
                <option value="mk">Macedonia</option>
                <option value="my">Malaysia</option>
                <option value="mt">Malta</option>
                <option value="mx">Mexico</option>
                <option value="md">Moldova</option>
                <option value="mc">Monaco</option>
                <option value="me">Montenegro</option>
                <option value="ma">Morocco</option>
                <option value="mz">Mozambique</option>
                <option value="mm">Myanmar</option>
                <option value="np">Nepal</option>
                <option value="nl">Netherlands</option>
                <option value="nz">New Zealand</option>
                <option value="ng">Nigeria</option>
                <option value="no">Norway</option>
                <option value="om">Oman</option>
                <option value="pk">Pakistan</option>
                <option value="pa">Panama</option>
                <option value="pe">Peru</option>
                <option value="ph">Philippines</option>
                <option value="pl">Poland</option>
                <option value="pt">Portugal</option>
                <option value="pr">Puerto Rico</option>
                <option value="qa">Qatar</option>
                <option value="ro">Romania</option>
                <option value="ru">Russian Federation</option>
                <option value="st">Sao Tome and Principe</option>
                <option value="sa">Saudi Arabia</option>
                <option value="rs">Serbia</option>
                <option value="sg">Singapore</option>
                <option value="sk">Slovak Republic</option>
                <option value="si">Slovenia</option>
                <option value="za">South Africa</option>
                <option value="es">Spain</option>
                <option value="lk">Sri Lanka</option>
                <option value="se">Sweden</option>
                <option value="ch">Switzerland</option>
                <option value="tw">Taiwan</option>
                <option value="tz">Tanzania</option>
                <option value="th">Thailand</option>
                <option value="tg">Togo</option>
                <option value="to">Tonga</option>
                <option value="tt">Trinidad and Tobago</option>
                <option value="tn">Tunisia</option>
                <option value="tr">Turkey</option>
                <option value="ug">Uganda</option>
                <option value="ua">Ukraine</option>
                <option value="ae">United Arab Emirates</option>
                <option value="uy">Uruguay</option>
                <option value="uz">Uzbekistan</option>
                <option value="ve">Venezuela</option>
                <option value="vn">Vietnam</option>
                <option value="zw">Zimbabwe</option>
              </Form.Control>                      
            </Form.Group>            
            <Form.Group as={Col} md>
              <Form.Row>
                <Col xs="auto">
                  <Form.Label>{t('linkedIn.jobTitle')}</Form.Label>
                </Col>
                <Col className="d-flex justify-content-end">
                  <OverlayTooltip 
                    message={t('linkedIn.jobTitleToolTip')}
                  />
                </Col>
              </Form.Row>          
              <Form.Control                 
                placeholder={t('linkedIn.jobTitlePlaceHolder')} 
                value={job} 
                onChange={(event) => setJob(event.target.value)}
              />          
              <Form.Check 
                type="checkbox" 
                label={t('linkedIn.similarJobs')} 
                value={similar} 
                onChange={() => setSimilar(!similar)}
              />
            </Form.Group>
          </Form.Row>      
          <Form.Row>
            <Form.Group as={Col} md>
              <Form.Row>
                <Col xs="auto">
                  <Form.Label>{t('linkedIn.locationInclude')}</Form.Label>
                </Col>
                <Col className="d-flex justify-content-end">
                  <OverlayTooltip 
                    message={t('linkedIn.locationIncludeToolTip')}
                  />
                </Col>
              </Form.Row>                    
              <Form.Control              
                placeholder={t('linkedIn.locationIncludePlaceHolder')} 
                value={location} 
                onChange={(event) => setLocation(event.target.value)}
              />          
            </Form.Group>
            <Form.Group as={Col} md>
              <Form.Row>
                <Col xs="auto">
                  <Form.Label>{t('linkedIn.keywordExclude')}</Form.Label>
                </Col>
                <Col className="d-flex justify-content-end">
                  <OverlayTooltip 
                    message={t('linkedIn.keywordExcludeToolTip')}
                  />
                </Col>
              </Form.Row>                              
              <Form.Control               
                placeholder={t('linkedIn.keywordExcludePlaceHolder')} 
                value={exclude} 
                onChange={(event) => setExclude(event.target.value)}
              />          
            </Form.Group>
          </Form.Row>      
          <Form.Row>          
            <Form.Group as={Col} md>
              <Form.Row>
                <Col xs="auto">
                  <Form.Label>{t('linkedIn.education')}</Form.Label>
                </Col>
                <Col className="d-flex justify-content-end">
                  <OverlayTooltip message={t('linkedIn.educationToolTip')}/>
                </Col>
              </Form.Row>                                        
              <Form.Control 
                as="select" 
                value={education} 
                onChange={(event) => setEducation(event.target.value)}
              >
                <option value="all">{t('linkedIn.educationAll')}</option>
                <option value="bachelor+degree+licence">{t('linkedIn.educationDegree')}</option>
                <option value="masters+mba+master+diplome+msc+magister+magisteres+maitrise">{t('linkedIn.educationMastersDegree')}</option>
                <option value="dr+Ph.D.+PhD+D.Phil+DPhil+doctor+Doctorado+Doktor+Doctorat+Doutorado+DrSc+Tohtori+
                Doctorate+Doctora+Duktorah+Dottorato+Daktaras+Doutoramento+Doktorgrad">{t('linkedIn.educationDoctoralDegree')}</option>
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} md>
              <Form.Row>
                <Col xs="auto">
                  <Form.Label>{t('linkedIn.currentEmployer')}</Form.Label>
                </Col>
                <Col className="d-flex justify-content-end">
                  <OverlayTooltip 
                    message={t('linkedIn.currentEmployerToolTip')}
                  />
                </Col>
              </Form.Row>          
              <Form.Control               
                placeholder={t('linkedIn.currentEmployerPlaceHolder')}               
                value={employer} 
                onChange={(event) => setEmployer(event.target.value)}
              />          
            </Form.Group>
          </Form.Row>      
          <Button 
            className="form-button"
            variant="success" 
            size="lg"            
            onClick={handleClickButton}                              
            block
          >
            {t('linkedIn.findPeople')}
          </Button>                                    
      </Form>      
    </> 
  );
}

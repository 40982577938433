import React, { Suspense } from 'react';
import { Container } from 'react-bootstrap';
import './App.css';
import TabsWithSearchResume from './components/TabsWithSearchResume';
import ReactGA from 'react-ga';
import { GoogleAnalyticsID } from './constant';
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

function App() {
  let { t } = useTranslation();

  ReactGA.initialize(GoogleAnalyticsID);
  
  return (    
    <Suspense fallback="loading">
      
      <Container>
        <Helmet>
          <title>{t('title')}</title>
          <meta
            name="description"
            content={t('description')}
          />
        </Helmet>
        <TabsWithSearchResume />
      </Container>
    </Suspense>
  );
}

export default App;

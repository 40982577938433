import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoIosHelpCircle } from 'react-icons/io'

function OverlayTooltip(props) {    

  return ( 
    <OverlayTrigger                                         
      placement="left"                                        
      overlay={
        <Tooltip id={"tooltip-left"}>
          {props.message}
        </Tooltip>}
      >
        <IoIosHelpCircle size="1.5em"/>                    
    </OverlayTrigger> 
  );
}

OverlayTooltip.propTypes = {
  message: PropTypes.string.isRequired
}

export default OverlayTooltip;
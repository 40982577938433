import React, { useState } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import OverlayTooltip from './OverlayTooltip';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';

export default function FormForLinkedIn() {
  const { t } = useTranslation();
  
  const [validated, setValidated] = useState(false);  
  const [include, setInclude] = useState("");
  const [datePosted, setDatePosted] = useState("all");
  const [type, setType] = useState("all");

  function handleSubmitForm(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {      
      event.preventDefault();
      event.stopPropagation();      
      setValidated(true);
    }
    else {
      setValidated(true);
      event.preventDefault();
      openLink();      
    }    
  }

  function openLink() {
    let currentInclude = include.split(" ").join("+");
    ReactGA.ga('send', 'event', {
      eventCategory: 'GoogleJobs',
      eventAction: include,
      eventLabel: 'keywords: '+currentInclude+', type: '+type+', date: '+datePosted
    });
    window.open(
      "https://www.google.com/search?q=" + 
      (currentInclude ? currentInclude : "any") + 
      "&ibp=htl%3Bjobs#fpstate=tldetail&htivrt=jobs&htichips=" + 
      ((datePosted !== "all") ? "date_posted:" + datePosted + "," : "") +
      ((type !== "all") ? "employment_type:" + type : "") +
      ((datePosted !== "all") ? "date_posted;" + datePosted + "," : "") +
      ((type !== "all") ? "employment_type;" + type : "")
    );  
  }
  
  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmitForm}>
        <div className="form-title">
          {t('googleJobs.title')}
        </div>
          <Form.Row>            
            <Form.Group as={Col} md>  
              <Form.Row>
                <Col xs="auto">
                  <Form.Label>{t('googleJobs.locationInclude')}</Form.Label>
                </Col>
                <Col className="d-flex justify-content-end">
                  <OverlayTooltip 
                    message={t('googleJobs.locationIncludeToolTip')}
                  />
                </Col>
              </Form.Row>                    
              <Form.Control              
                required
                placeholder={t('googleJobs.locationIncludePlaceHolder')} 
                value={include} 
                onChange={(event) => setInclude(event.target.value)}
              />
            </Form.Group>            
          </Form.Row>      
          <Form.Row>
            <Form.Group as={Col} md>
              <Form.Row>
                <Col xs="auto">
                  <Form.Label>{t('googleJobs.type')}</Form.Label>
                </Col>
                <Col className="d-flex justify-content-end">
                  <OverlayTooltip 
                    message={t('googleJobs.typeToolTip')}
                  />
                </Col>
              </Form.Row>
              <Form.Control 
                as="select" 
                value={type} 
                onChange={(event) => setType(event.target.value)}
              >
                <option value="all">{t('googleJobs.typeAll')}</option>
                <option value="FULLTIME">{t('googleJobs.typeFullTime')}</option>
                <option value="CONTRACTOR">{t('googleJobs.typeContractor')}</option>
                <option value="INTERN">{t('googleJobs.typeInternship')}</option>
                <option value="PARTTIME">{t('googleJobs.typeParTime')}</option>
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} md>
              <Form.Row>
                <Col xs="auto">
                  <Form.Label>{t('googleJobs.datePosted')}</Form.Label>
                </Col>
                <Col className="d-flex justify-content-end">
                  <OverlayTooltip message={t('googleJobs.datePostedToolTip')}/>
                </Col>
              </Form.Row>                                        
              <Form.Control 
                as="select" 
                value={datePosted} 
                onChange={(event) => setDatePosted(event.target.value)}
              >
                <option value="all">{t('googleJobs.datePostedAll')}</option>
                <option value="today">{t('googleJobs.datePostedPastDay')}</option>
                <option value="3days">{t('googleJobs.datePostedPast3Days')}</option>
                <option value="week">{t('googleJobs.datePostedPastWeek')}</option>
                <option value="month">{t('googleJobs.datePostedPastMonth')}</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Button 
            className="form-button"
            variant="success" 
            size="lg"            
            type="submit"
            block
          >
            {t('googleJobs.findPeople')}
          </Button>                                    
      </Form>
    </> 
  );
}

import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import FormForLinkedIn from './FormForLinkedIn';
import FormForXing from './FormForXing';
import FormForTwitter from './FormForTwitter';
import FormForGoogleJobs from './FormForGoogleJobs';
import ReactGA from 'react-ga';

export default function TabsWithSearchResume() {
  return (
    <Tabs defaultActiveKey="linkedin" id="uncontrolled-tab-example" onSelect={eventKey => ReactGA.pageview(eventKey)}>
      <Tab eventKey="linkedin" title="LinkedIn">
        <FormForLinkedIn />
      </Tab>
      <Tab eventKey="xing" title="Xing">
        <FormForXing />
      </Tab>
      <Tab eventKey="twitter" title="Twitter">
        <FormForTwitter />
      </Tab>
      <Tab eventKey="googleJob" title="Google Jobs">
        <FormForGoogleJobs />
      </Tab>
    </Tabs>
  );
}
